import './Services.css'
import Header from '../components/Header';
import PageBreak from '../components/PageBreak'
import { Link } from 'react-router-dom'
import { IoFolderOpenOutline, IoChatbubblesOutline, IoCalendarOutline, IoPeopleOutline, IoBookOutline, IoBusinessOutline } from "react-icons/io5"

const Services = () => {
    return (
        <div className='services'>
            <div className='pages-header-container'>
                <Header 
                    name="Services" 
                    desc="Accounting, bookkeeping, taxes and advice."
                    image='../acct_image5.jpg'
                />
            </div>
            <div className="services-svc-container">
                <h2>Small Business Tax Services</h2>
                <p>Incan Tax & Accounting is known for our quality, professionalism, and knowledge of the latest tax and accounting standards. As a full-service accounting firm, our strategy is to provide seamless services throughout the clients journey.</p>
                <div className="services-svc">
                    <Link to='/services/personaltax'>
                        <div>
                            <IoFolderOpenOutline size={50}/>
                            <h3>PERSONAL TAX</h3>
                            <p>We organize and manage your personal taxes, ensuring compliance with tax regulations, optimizing deductions, and minimizing liabilities.</p>
                        </div>
                    </Link>
                    <Link to='/services/corporatetax'>
                        <div>
                            <IoChatbubblesOutline size={50}/>
                            <h3>COORPORATE TAX</h3>
                            <p>We provide comprehensive tax support for your business, including tax optimization, reduction of tax liabilities, and full compliance with corporate tax laws.</p>
                        </div>
                    </Link>
                    <Link to='/services/gstfiling'>
                        <div>
                            <IoCalendarOutline size={50}/>
                            <h3>GST FILING</h3>
                            <p>Accurate and timely filing of Goods and Services Tax (GST) to ensure compliance and streamline the process for businesses.</p>
                        </div>
                    </Link>
                    <Link to='/services/payroll'>
                        <div>
                            <IoPeopleOutline size={50}/>  
                            <h3>PAYROLL</h3>
                            <p>Precise and hassle-free payroll management, ensuring accurate and timely employee payments and handling tax withholdings and compliance.</p>
                        </div>
                    </Link>
                    <Link to='/services/bookkeeping'>
                        <div>
                            <IoBookOutline size={50}/>
                            <h3>BOOKKEEPING</h3>
                            <p>Accurate financial record-keeping and reporting, helping individuals and businesses maintain financial transparency and make informed decisions.</p>
                        </div>
                    </Link>
                    <Link to='/services/business-setup'>
                        <div>
                            <IoBusinessOutline size={50}/>
                            <h3>BUSINESS SETUP</h3>
                            <p>Guidance and support for establishing different business entities, including necessary registrations, licensing, and paperwork to ensure a smooth and legally compliant business setup.</p>
                        </div>
                    </Link>
                </div>
            </div>
            <PageBreak desc="We are proud to have worked with customers from various industries."/>
            <div className='service-industries-container'>
                <div className="service-industries-item">
                    <img src="../industry-truck.jpg" alt="industry" />
                    <div className="service-industries-item-title">
                        <h4>Transportation</h4>
                        <p>Accounting services for the transportation industry specialize in managing finances, expenses, and regulatory compliance for transportation companies, enhancing operational efficiency and profitability.</p>
                    </div>
                </div>
                <div className="service-industries-item">
                    <img src="../industry-construction.png" alt="industry" />
                    <div className="service-industries-item-title">
                        <h4>Construction</h4>
                        <p>Accounting services for construction: Tailored financial management for construction companies, including budgeting, payroll, cost tracking, tax compliance, and project-specific reporting.</p>
                    </div>
                </div>
                <div className="service-industries-item">
                    <img src="../industry-realestate.jpg" alt="industry" />
                    <div className="service-industries-item-title">
                        <h4>Real Estate</h4>
                        <p>Real estate accounting: Managing financial aspects of property ownership, rent, expenses, and compliance with industry regulations for real estate businesses.</p>
                    </div>
                </div>
                <div className="service-industries-item">
                    <img src="../industry-retail.jpg" alt="industry" />
                    <div className="service-industries-item-title">
                        <h4>Retail</h4>
                        <p>Retail accounting: Financial services customized for retail businesses, including sales analysis, inventory management, expense tracking, and tax compliance to optimize profitability and compliance.</p>
                    </div>
                </div>
            </div>
            <div className="service-other-container">
                <div className="service-other">
                    <h3>Let us know how we can support you!</h3>
                    <Link to='/contact'>
                        <button>Contact Us</button>
                    </Link>
                </div>
            </div>
        </div>
    );
}
 
export default Services;