import Header from "../../components/Header";
import { IoCheckmarkCircleSharp, IoPersonOutline, IoPeopleOutline } from "react-icons/io5";
import { Link } from 'react-router-dom';
import './PersonalTax.css';

const PersonalTax = () => {
    return (
        <div className="personaltax">
            <Header 
                name="Personal Tax" 
                desc="Simple tax solutions, for you and your family."
                image='../acct_image5.jpg'
            />
            <div className="personal-container">
                <h3>Your Personal Tax and Finances</h3>
                <p></p>
                <div className="personal-desc-container">
                    <p>As your small business accountant, we provide support with everything from investment income and capital gains to write-offs, expenses, deductions and income splitting. Personal tax includes various aspects, such as filing an annual tax return, managing tax liabilities, and ensuring compliance with tax laws and regulations.</p>
                    <div className="personal-desc-checklist">
                        <div>
                            <div className="checklist-item">
                                <IoCheckmarkCircleSharp size={30}/>
                                <p>Income Reporting</p>
                            </div>
                            <div className="checklist-item">
                                <IoCheckmarkCircleSharp size={30}/>
                                <p>Deductions and Credits</p>
                            </div>
                            <div className="checklist-item">
                                <IoCheckmarkCircleSharp size={30}/>
                                <p>Tax Forms</p>
                            </div>
                        </div>
                        <div>
                            <div className="checklist-item">
                                <IoCheckmarkCircleSharp size={30}/>
                                <p>Tax Filing</p>
                            </div>
                            <div className="checklist-item">
                                <IoCheckmarkCircleSharp size={30}/>
                                <p>Tax Refunds and Payments</p>
                            </div>
                            <div className="checklist-item">
                                <IoCheckmarkCircleSharp size={30}/>
                                <p>Tax Compliance</p>
                            </div>
                        </div>
                    </div>
                    <p>The goal of personal tax management is to minimize tax liabilities while staying within the bounds of the law. Tax laws can be complex, and individuals often seek assistance from tax professionals or use tax software to ensure accurate and compliant tax filings.</p>
                </div>
                <div className="personal-pricing-container">
                    <div className="personal-pricing-item-container">
                        <div className="personal-pricing-item">
                            <IoPersonOutline size={100}/>
                            <h3>INDIVIDUAL</h3>
                            <p>We specialize in providing individual personal tax services, offering tailored solutions to navigate tax complexities, maximize deductions, and ensure compliance for our clients.</p>
                            <p>Starting From:</p>
                            <h2>$30</h2>
                        </div>
                        <div className="personal-pricing-item">
                            <IoPeopleOutline size={100}/>
                            <h3>FAMILY</h3>
                            <p>We are dedicated to providing top-notch family personal tax services, tailoring our expertise to address the distinctive financial needs of households, ensuring comprehensive support for a range of family-related tax considerations.</p>
                            <p>Starting From:</p>
                            <h2>$50</h2>
                        </div>
                    </div>
                </div>
                <div className="personal-contact-btn">
                    <h3>Let us know how we can support you!</h3>
                    <Link to='/contact'>
                        <button>Contact Us</button>
                    </Link>
                </div>
            </div>
        </div>
    );
}
 
export default PersonalTax;