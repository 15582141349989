import { Link } from 'react-router-dom';
import { IoMenu, IoClose } from "react-icons/io5";
import { useState } from 'react';

const Navbar = () => {
    const [ menuStatus, setMenuStatus] = useState(false);

    const openMenu = () => {
        if (menuStatus === true) {
            setMenuStatus(false);
        } else {
            setMenuStatus(true);
        }
    }

    return (
        <nav className='navbar-container'>
            <div className={menuStatus ? 'mobile-links' : 'mobile-links-hidden'}>
                <Link to='/' onClick={openMenu}>
                    <p>Home</p>
                </Link>
                <Link to='/about' onClick={openMenu}>
                    <p>About</p>
                </Link>
                <Link to='/services' onClick={openMenu}>
                    <p>Services</p>
                </Link>
                <Link to='/resources' onClick={openMenu}>
                    <p>Resources</p>
                </Link>
                <Link to='/contact' onClick={openMenu}>
                    <p>Contact</p>
                </Link>
            </div>
            <div className="content">
                <div className="logo">
                    <Link to='/'>
                        <h2>Incan</h2>
                    </Link>
                </div>
                <div className="mobile-container">
                    <button className="mobile-btn" onClick={openMenu}>
                        {menuStatus ? <IoClose size={50}/> : <IoMenu size={50}/>}
                    </button>
                </div>
                <div className="links">
                    <Link to='/'>
                        <p>Home</p>
                    </Link>
                    <Link to='/about'>
                        <p>About</p>
                    </Link>
                    <Link to='/services' className='nav-drop'>
                        <p>Services</p>
                        <div className="dropdown-content">
                            <Link to='/services/personaltax'>
                                <p>Personal Tax</p>
                            </Link>
                            <Link to='/services/corporatetax'>
                                <p>Coorporate Tax</p>
                            </Link>
                            <Link to='/services/gstfiling'>
                                <p>GST Filing</p>
                            </Link>
                            <Link to='/services/payroll'>
                                <p>Payroll</p>
                            </Link>
                            <Link to='/services/bookkeeping'>
                                <p>Bookkeeping</p>
                            </Link>
                            <Link to='/services/business-setup'>
                                <p>Business Setup</p>
                            </Link>
                        </div>
                    </Link>
                    <Link to='/resources' className='nav-drop'>
                        <p>Resources</p>
                        <div className="dropdown-content">
                            <Link to='/resources/news'>
                                <p>News</p>
                            </Link>
                            <Link to='/resources/FAQ'>
                                <p>FAQ</p>
                            </Link>
                            <Link to='/resources/tools'>
                                <p>Tools</p>
                            </Link>
                        </div>
                    </Link>
                    <Link to='/contact'>
                        <p>Contact Us</p>
                    </Link>
                </div>
            </div>
        </nav>
    );
}
 
export default Navbar;