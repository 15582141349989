import Header from "../../components/Header";
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import { Link } from 'react-router-dom';

const GSTFiling = () => {
    return (
        <div>
            <Header 
                name="GST Filing" 
                desc="Organizing your business' Goods and Services Tax."
                image='../acct_image5.jpg'
            />
            <div className="personal-container">
                <h3>Supporting you in GST Filing</h3>
                <p></p>
                <div className="personal-desc-container">
                    <p>GST (Goods and Services Tax) filing is a crucial financial and regulatory process for businesses. It involves reporting and remitting the GST collected on sales or services to tax authorities. GST is a value-added tax applied to the supply of goods and services at each stage of production or distribution, ultimately borne by the end consumer. The process of GST filing is designed to ensure businesses comply with tax laws and accurately report their GST-related activities. Key elements of GST Filing include:</p>
                    <div className="personal-desc-checklist">
                        <div>
                            <div className="checklist-item">
                                <IoCheckmarkCircleSharp size={30}/>
                                <p>Collecting GST</p>
                            </div>
                            <div className="checklist-item">
                                <IoCheckmarkCircleSharp size={30}/>
                                <p>Input Tax Credit</p>
                            </div>
                            <div className="checklist-item">
                                <IoCheckmarkCircleSharp size={30}/>
                                <p>GST Returns</p>
                            </div>
                        </div>
                        <div>
                            <div className="checklist-item">
                                <IoCheckmarkCircleSharp size={30}/>
                                <p>Calculating and Reporting</p>
                            </div>
                            <div className="checklist-item">
                                <IoCheckmarkCircleSharp size={30}/>
                                <p>Payment</p>
                            </div>
                            <div className="checklist-item">
                                <IoCheckmarkCircleSharp size={30}/>
                                <p>Tax Compliance</p>
                            </div>
                        </div>
                    </div>
                    <p>GST filing can be complex, especially for businesses operating in multiple jurisdictions or dealing with specific types of products or services subject to different GST rates. Many businesses rely on accounting software or the expertise of tax professionals to manage GST filing efficiently and ensure accuracy in their tax reporting and remittance.</p>
                </div>
                <div className="personal-contact-btn">
                    <h3>Contact us for pricing today.</h3>
                    <Link to='/contact'>
                        <button>Contact Us</button>
                    </Link>
                </div>
            </div>
        </div>
    );
}
 
export default GSTFiling;