import Header from "../../components/Header";
import './News.css';
import { Link } from 'react-router-dom';

const newsItems = [
    {
        image: '../acct_image1.jpg',
        date: 'September 19, 2023',
        title: 'Underused Housing Tax',
        desc: 'The Underused Housing Tax is an annual federal 1% tax on the ownership of vacant or underused housing in Canada that took effect on January 1, 2022.',
        newsLink: 'https://www.canada.ca/en/services/taxes/excise-taxes-duties-and-levies/underused-housing-tax.html'
    },
    {
        image: '../acct_image2.jpg',
        date: 'May 4, 2023',
        title: 'The Canada Pension Plan enhancement – Businesses, individuals, and self-employed',
        desc: 'The Canada Pension Plan (CPP) enhancement, which was introduced on January 1, 2019, is designed to help increase retirement income for working Canadians and their families.',
        newsLink: 'https://www.canada.ca/en/revenue-agency/news/2023/05/the-canada-pension-plan-enhancement--businesses-individuals-and-self-employed-what-it-means-for-you.html'
    },
    {
        image: '../acct_image3.jpg',
        date: 'February 20, 2023',
        title: 'The Canada Revenue Agency kicks off the 2023 tax-filing season: Canadians need to file their returns before May 1',
        desc: 'Tax season has officially kicked off and many Canadians will turn to the Canada Revenue Agency (CRA) for guidance...',
        newsLink: 'https://www.canada.ca/en/revenue-agency/news/2023/02/the-canada-revenue-agency-kicks-off-the-2023-tax-filing-season-canadians-need-to-file-their-returns-before-may-1.html'
    },
    {
        image: '../acct_image4.jpg',
        date: 'February 12, 2023',
        title: "What to remember when filing your taxes in Alberta this year",
        desc: "Tax season is upon Alberta residents and the Canada Revenue Agency (CRA) is getting ready to answer questions from taxpayers and make it easier and quicker for them to file their 2022 income tax and benefit return.",
        newsLink: 'https://www.canada.ca/en/revenue-agency/news/2023/02/whats-new-and-what-to-remember-when-filing-your-taxes-in-alberta-this-year.html'
    },
    {
        image: '../acct_image5.jpg',
        date: 'December 5, 2022',
        title: 'The Government of Canada launches formal consultations to streamline reporting of payroll information ',
        desc: 'The Government of Canada is committed to refining the way it delivers programs and services to Canadians and modernizing how businesses provide payroll information to the federal government, while protecting...',
        newsLink: 'https://www.canada.ca/en/revenue-agency/news/2022/12/the-government-of-canada-launches-formal-consultations-to-streamline-reporting-of-payroll-information.html'
    }
]

const News = () => {
    return (
        <div className="news">
            <Header 
                name="News" 
                desc="The latest tax, accounting and bookkeeping news in Canada."
                image='../acct_image10.jpg'
            />
            <div className="news-container">
                <h3>Keeping you up to date!</h3>
                <p>Welcome to our News Section, your source for the latest updates, developments, and insights. At Incan Tax and Accounting Inc., we believe that staying informed is essential, and this section is designed to keep you up-to-date with breaking news, trends, and stories that matter most to you.</p>
                <div className="news-items-container">
                    {
                        newsItems.map((item) => (
                            <div className="news-item">
                                <img src={item.image} alt="placeholder" />
                                <div className="news-item-content">
                                    <p>{item.date}</p>
                                    <strong>{item.title}</strong>
                                    <p>{item.desc}</p>
                                    <Link to={item.newsLink} target="_blank">Continue to Article</Link>
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className="news-contact-btn">
                    <h3>For further information on the latest news!</h3>
                    <Link to='/contact'>
                        <button>Contact Us</button>
                    </Link>
                </div>
            </div>
        </div>
    );
}
 
export default News;