import Header from "../../components/Header";
import { IoBookOutline, IoCalculatorOutline } from "react-icons/io5"
import './Tools.css';
import { Link } from 'react-router-dom';

const Tools = () => {
    return (
        <div className="tools">
            <Header 
                name="Tools" 
                desc="Essential personal and small business resources."
                image='../acct_image12.jpg'
            />
            <div className="tools-container">
                <h3>Tools, Templates and Calculators</h3>
                <p>Some resources to put in your pocket: accounting and bookkeeping templates and samples. Plus, the best in small business calculators.</p>
                <div className="tools-templates-container">
                    <div className="tools-templates-item-container">
                        <IoBookOutline size={120}/>
                        <h3>Templates</h3>
                    </div>
                    <div className="tools-templates-items">
                        <ul>
                            <li><Link to="https://www.taxtips.ca/taxrates/ab.htm">Alberta Tax Brackets</Link></li>
                        </ul>
                    </div>
                </div>
                <div className="tools-templates-container">
                    <div className="tools-templates-item-container">
                        <IoCalculatorOutline size={120}/>
                        <h3>Calculators</h3>
                    </div>
                    <div className="tools-templates-items">
                        <ul>
                            <li><Link target="_blank" to="https://www.canada.ca/en/revenue-agency/services/e-services/digital-services-businesses/payroll-deductions-online-calculator.html">Payroll Deductions Online Calculator</Link></li>
                            <li><Link target="_blank" to="https://www.canada.ca/en/revenue-agency/services/child-family-benefits/child-family-benefits-calculator.html">Child and Family Benefits Calculator</Link></li>
                            <li><Link target="_blank" to="https://turbotax.intuit.ca/tax-resources/alberta-income-tax-calculator.jsp">Alberta Tax Calculator</Link></li>
                            <li><Link target="_blank" to="https://www.td.com/ca/en/personal-banking/products/mortgages/calculators-tools?GOOGLE&Mortgage+Calculator+-+Brand+-+English+(23_S_RL_RMO_AO_ACQ_ENFR)&Calculator+-+Brand&&&&&ds_rl=1265821&ds_rl=1266773&ds_rl=1266773&ds_rl=1265821&gclid=EAIaIQobChMI-uzB6oiAggMVFg2tBh2a5gFrEAAYASAAEgJBj_D_BwE&gclsrc=aw.ds">Mortgage Calculator</Link></li>
                        </ul>
                    </div>
                </div>
                <div className="FAQ-contact-btn">
                    <h3>For any other questions, contact us today!</h3>
                    <Link to='/contact'>
                        <button>Contact Us</button>
                    </Link>
                </div>
            </div>
        </div>
    );
}
 
export default Tools;