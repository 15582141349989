import Header from "../../components/Header";
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import { Link } from 'react-router-dom';

const BookKeeping = () => {
    return (
        <div>
            <Header 
                name="Bookkeeping" 
                desc="Lorem ipsum dolor, sit amet consectetur adipisicing elit. Vel, quod."
                image='../acct_image5.jpg'
            />
            <div className="personal-container">
                <h3>Bookkeeping of your personal and business' tax information</h3>
                <p></p>
                <div className="personal-desc-container">
                    <p>Bookkeeping is the systematic process of recording, organizing, and maintaining financial transactions for a business or individual. It is an essential aspect of financial management and provides a clear, organized record of an entity's financial activities. Key elements of bookkeeping include:</p>
                    <div className="personal-desc-checklist">
                        <div>
                            <div className="checklist-item">
                                <IoCheckmarkCircleSharp size={30}/>
                                <p>Recording Financial Transactions</p>
                            </div>
                            <div className="checklist-item">
                                <IoCheckmarkCircleSharp size={30}/>
                                <p>Double Entry Accounting</p>
                            </div>
                            <div className="checklist-item">
                                <IoCheckmarkCircleSharp size={30}/>
                                <p>Categorizing Transactions</p>
                            </div>
                            <div className="checklist-item">
                                <IoCheckmarkCircleSharp size={30}/>
                                <p>Reconciliation</p>
                            </div>
                        </div>
                        <div>
                            <div className="checklist-item">
                                <IoCheckmarkCircleSharp size={30}/>
                                <p>Financial Reporting</p>
                            </div>
                            <div className="checklist-item">
                                <IoCheckmarkCircleSharp size={30}/>
                                <p>Tax Compliance</p>
                            </div>
                            <div className="checklist-item">
                                <IoCheckmarkCircleSharp size={30}/>
                                <p>Financial Analysis</p>
                            </div>
                            <div className="checklist-item">
                                <IoCheckmarkCircleSharp size={30}/>
                                <p>Audit Support</p>
                            </div>
                        </div>
                        <div>
                            <div className="checklist-item">
                                <IoCheckmarkCircleSharp size={30}/>
                                <p>Reports and Documentation</p>
                            </div>
                            <div className="checklist-item">
                                <IoCheckmarkCircleSharp size={30}/>
                                <p>Budgeting and Forecasting</p>
                            </div>
                            <div className="checklist-item">
                                <IoCheckmarkCircleSharp size={30}/>
                                <p>Record Retention</p>
                            </div>
                        </div>
                    </div>
                    <p>Accurate and well-organized bookkeeping is crucial for maintaining financial transparency, tracking income and expenses, and ensuring compliance with tax regulations. It serves as the foundation for sound financial management and reporting, supporting the overall financial health of businesses and individuals. Many businesses use specialized accounting software or hire professional bookkeepers to manage their bookkeeping tasks efficiently.</p>
                </div>
                <div className="personal-contact-btn">
                    <h3>Contact us for pricing today.</h3>
                    <Link to='/contact'>
                        <button>Contact Us</button>
                    </Link>
                </div>
            </div>
        </div>
    );
}
 
export default BookKeeping;