import { Link } from 'react-router-dom'
import './Home.css'
import PageBreak from '../components/PageBreak';

const Home = () => {
    return (
        <div className="home-container">
            <div className="home-title-banner">
                <img className="home-title-img" src='./acct_image4.jpg' alt="title-img"/>
                <div className="home-title">
                    <h1>Incan Tax & Accounting Inc.</h1>
                    <p>From accounting, bookkeeping and taxes to small business advice, our job is about setting you up for success.</p>
                    <Link to='/contact'>
                        <button>Contact Us</button>
                    </Link>
                </div>
            </div>
            <div className='home-abt-container'>
                <div>
                    <h2>Welcome to Incan Tax & Accounting Inc.</h2>
                    <p>With 14+ years of experience, we've cultivated a deep understanding of the financial challenges that individuals and small businesses face. We are committed to helping you navigate your financial journey with precision and care. We invite you to partner with us on your financial journey. Whether you're an individual seeking tax assistance or a small business aiming for financial excellence, we're here to help you achieve your financial aspirations. We specialize in Personal and Corporate Tax, GST Filing, Payroll, Bookkeeping and New Business Consultation. Contact us today to get started.</p>
                </div>
            </div>
            <PageBreak desc="We're excited to have the opportunity to work with you!"/>
            <div className="website-highlights">
                <h2>We're here to help</h2>
                <p>See how we can assist you in all of your income tax and accounting related queries. Check out our servies, tools and blog pages for more information.</p>
                <div className='highlights-row'>
                    <div className="highlight">
                        <img src="../acct_image5.jpg" alt="highlight" />
                        <div className="highlight-inner">
                            <h1>Services</h1>
                            <Link to='/services'>
                                <button>VIEW OUR SERVICES</button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className='highlights-row highlights-row-2'>
                    <Link to="/resources" className='highlight highlight-row2'>
                        <img src="../acct_image6.jpg" alt="highlight" />
                        <div className="highlight-inner">
                            <h1>Resources</h1>
                        </div>
                    </Link>
                    <Link to="/resources/tools" className="highlight highlight-row2">
                        <img src="../acct_image4.jpg" alt="highlight" />
                        <div className="highlight-inner">
                            <h1>Tools</h1>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );
}
 
export default Home;