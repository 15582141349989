import Header from "../../components/Header";
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import { Link } from 'react-router-dom';

const BusSetup = () => {
    return (
        <div>
            <Header 
                name="Business Setup" 
                desc="Helping you set up your business."
                image='../acct_image5.jpg'
            />
            <div className="personal-container">
                <h3>Setting up your Business</h3>
                <p></p>
                <div className="personal-desc-container">
                    <p>Business setup, also known as business formation or company incorporation, is the process of establishing a legally recognized entity that allows individuals or groups to conduct commercial activities and offer goods or services. The specific steps and requirements for business setup can vary depending on the legal and regulatory framework of the jurisdiction in which the business operates. Key components of business setup include:</p>
                    <div className="personal-desc-checklist">
                        <div>
                            <div className="checklist-item">
                                <IoCheckmarkCircleSharp size={30}/>
                                <p>Business Structure Selection</p>
                            </div>
                            <div className="checklist-item">
                                <IoCheckmarkCircleSharp size={30}/>
                                <p>Name Registration</p>
                            </div>
                            <div className="checklist-item">
                                <IoCheckmarkCircleSharp size={30}/>
                                <p>Registration with Government Authorities</p>
                            </div>

                        </div>
                        <div>
                            <div className="checklist-item">
                                <IoCheckmarkCircleSharp size={30}/>
                                <p>Tax ID and Permits</p>
                            </div>
                            <div className="checklist-item">
                                <IoCheckmarkCircleSharp size={30}/>
                                <p>Business Plan</p>
                            </div>
                            <div className="checklist-item">
                                <IoCheckmarkCircleSharp size={30}/>
                                <p>Compliance with Tax Regulations</p>
                            </div>
                        </div>
                    </div>
                    <p>Business setup is a critical step in launching a new venture or expanding an existing business. The process typically involves careful planning, legal considerations, and adherence to government regulations to establish a solid foundation for successful operations. Legal and financial professionals, as well as business consultants, can be valuable resources during the business setup process.</p>
                </div>
                <div className="personal-contact-btn">
                    <h3>Contact us for pricing today.</h3>
                    <Link to='/contact'>
                        <button>Contact Us</button>
                    </Link>
                </div>
            </div>
        </div>
    );
}
 
export default BusSetup;