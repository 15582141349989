import { Link } from 'react-router-dom'
import { IoCall, IoMail, IoLocationSharp } from "react-icons/io5"

const Footer = () => {
    return (
        <div className="footer-container">
            <div className="footer-content">
                <div className="footer-about">
                    <p>Incan Tax & Accounting Inc</p>
                    <p>With 14+ years of experience in the field of accounting, we invite you to partner with us on your financial journey. Whether you're an individual seeking tax assistance or a small business aiming for financial excellence, we're here to help you achieve your financial aspirations. We specialize in Personal and Corporate Tax, GST Filing, Payroll, Bookkeeping and New Business Consultation. </p>
                </div>
                <div className="footer-quick-links footer-content-item">
                    <p>Quick Links</p>
                    <ul>
                        <li>
                            <Link to="/">Home</Link>
                        </li>
                        <li>
                            <Link to="/about">About</Link>
                        </li>
                        <li>
                            <Link to="/services">Services</Link>
                        </li>
                        <li>
                            <Link to="/resources">Blog</Link>
                        </li>
                        <li>
                            <Link to="/contact">Contact</Link>
                        </li>
                    </ul>
                </div>
                <div className="footer-services footer-content-item">
                    <p>Services</p>
                        <ul>
                            <li>
                                <Link to="/services/personaltax">Personal Tax</Link>
                            </li>
                            <li>
                                <Link to="/services/corporatetax">Corporate Tax</Link>
                            </li>
                            <li>
                                <Link to="/services/gstfiling">GST Filing</Link>
                            </li>
                            <li>
                                <Link to="/services/payroll">Payroll</Link>
                            </li>
                            <li>
                                <Link to="/services/bookkeeping">Bookkeeping</Link>
                            </li>
                            <li>
                                <Link to="/services/business-setup">Business Setup</Link>
                            </li>
                        </ul>
                </div>
                <div className="footer-blog footer-content-item">
                    <p>Resources</p>
                        <ul>
                            <li>
                                <Link to="/resources/news">News</Link>
                            </li>
                            <li>
                                <Link to="/resources/FAQ">FAQ</Link>
                            </li>
                            <li>
                                <Link to="/resources/tools">Tools</Link>
                            </li>
                        </ul>
                </div>
                <div className="footer-contact-info footer-content-item">
                    <p>Contact Us</p>
                    <ul>
                        <li><IoCall/> (403) 852-1565</li>
                        <li><IoMail/><Link to="mailto:nmehmi@hotmail.com">nmehmi@hotmail.com</Link></li>
                        <li><IoLocationSharp/> 31 Cityspring Bay NE</li>
                    </ul>
                </div>
            </div>
            <div className="copyright">
                <p>&copy; Copyright Incan Tax & Accounting Inc. 2023</p>
            </div>
        </div>
    );
}
 
export default Footer;