const Header = (props) => {
    return (
        <div className='pages-header-container'>
            <img src={props.image} alt="headerimage" /> 
            <div className='pages-header-titles'>
                <h1>{props.name}</h1>
                <h4>{props.desc}</h4>
            </div>
        </div>
    );
}
 
export default Header;