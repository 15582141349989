import Header from "../../components/Header";
import './FAQ.css';
import FAQItem from "../../components/FAQItem";
import { Link } from 'react-router-dom';

const QandA = [
    {
        question: 'What is the difference between a corporation and a proprietorship?',
        answer: "A corporation is a separate legal entity from yourself. If you're incorporated, you would have ‘Articles of Incorporation,’ however registering for a business number or registering a trade name does not mean you are incorporated. If you incorporate your small business, you need to register for a business number and can also register a trade name. You do not need to incorporate to protect a trade name. A proprietorship means you are the business. You are able to operate a business, register for a business number and register a trade name, but still remain a proprietorship. Read our Guide for Sole Proprietors."
    },
    {
        question: 'What is the difference between Accounting and Bookkeeping?',
        answer: "While bookkeepers record the financial transactions of your business, accountants analyze the data and use it to generate reports and prepare financial reporting records, including tax returns, income statements and balance sheets. Accounting involves creating the balance sheet, accruing for non-cash transactions and calculating the income taxes. At this stage, we make sure you get credit for all the deductions and write-offs you qualify for. We’ll also explain where all your cash went and how much equity you are building in the business, review your company’s financial results, plan for the future, and e-file all the tax returns, GST returns, T4 slips (wages), T5 slips (dividends) and T5018 slips."
    },
    {
        question: 'What records should I keep for tax and accounting purposes?',
        answer: "It's important to keep records of income, expenses, receipts, invoices, bank statements, tax returns, and any relevant financial documentation. The retention period may vary, but it's typically several years."
    },
    {
        question: 'What is the difference between a tax credit and a tax deduction?',
        answer: "A tax deduction reduces the amount of income subject to taxation, while a tax credit directly reduces the tax owed. Tax credits are often more valuable since they provide a dollar-for-dollar reduction in taxes."
    },
    {
        question: 'How can I reduce my tax liability?',
        answer: "You can reduce your tax liability by taking advantage of tax deductions, tax credits, and tax-advantaged accounts, such as retirement accounts and Health Savings Accounts (HSAs)."
    },
    {
        question: 'What is an audit, and how does it work?',
        answer: "An audit is a review of financial records and statements to ensure accuracy and compliance with tax laws. Tax authorities may select a taxpayer for an audit, during which they examine financial records and request additional documentation as needed."
    },
    {
        question: 'What are common tax deductions for individuals?',
        answer: "Common tax deductions include mortgage interest, student loan interest, medical expenses, charitable contributions, and certain business expenses."
    },
    {
        question: 'When is the tax filing deadline?',
        answer: "In Canada, the deadline for personal income tax filing is typically April 30th. However, it may vary depending on the jurisdiction and individual circumstances."
    }
]

const FAQ = () => {


    return (
        <div className="FAQ">
            <Header 
                name="Frequenty Asked Questions" 
                desc="Our goal is to give you the knowledge to make the best tax decisions."
                image='../acct_image11.jpg'
            />
            <div className="FAQ-container">
                <h3>Answers to common tax questions</h3>
                <p>Welcome to our FAQ section, your go-to resource for quick and informative answers to the most frequently asked questions about accounting, bookkeeping, taxes and other related topics. We understand that you may have inquiries, and we've compiled this section to provide clarity, guidance, and assistance.</p>
                <div className="FAQ-questions-container">
                    {QandA.map((item) => (
                        <FAQItem question={item.question} answer={item.answer}/>
                    ))}
                </div>
            </div>
            <div className="FAQ-contact-btn">
                <h3>For any other questions, contact us today!</h3>
                <Link to='/contact'>
                    <button>Contact Us</button>
                </Link>
            </div>
        </div>
    );
}
 
export default FAQ;