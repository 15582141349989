import Header from "../../components/Header";
import { IoCheckmarkCircleSharp} from "react-icons/io5";
import { Link } from 'react-router-dom';

const CorpTax = () => {
    return (
        <div>
            <Header 
                name="Corporate Tax" 
                desc="Professional tax solutions, for your Business."
                image='../acct_image5.jpg'
            />
            <div className="personal-container">
                <h3>Corporate Tax and Finances</h3>
                <p></p>
                <div className="personal-desc-container">
                    <p>Corporate tax, also known as business tax or company tax, refers to the taxation of profits earned by incorporated businesses and other legal entities. It is a critical component of a country's tax system and represents a significant source of revenue for governments. Corporate tax regulations and rates can vary from one jurisdiction to another, and they typically apply to various types of business entities, including corporations, partnerships, and limited liability companies.</p>
                    <div className="personal-desc-checklist">
                        <div>
                            <div className="checklist-item">
                                <IoCheckmarkCircleSharp size={30}/>
                                <p>Taxable Income</p>
                            </div>
                            <div className="checklist-item">
                                <IoCheckmarkCircleSharp size={30}/>
                                <p>Tax Rates</p>
                            </div>
                            <div className="checklist-item">
                                <IoCheckmarkCircleSharp size={30}/>
                                <p>Tax Filing</p>
                            </div>
                        </div>
                        <div>
                            <div className="checklist-item">
                                <IoCheckmarkCircleSharp size={30}/>
                                <p>Deductions and Credit</p>
                            </div>
                            <div className="checklist-item">
                                <IoCheckmarkCircleSharp size={30}/>
                                <p>Tax Compliance</p>
                            </div>
                            <div className="checklist-item">
                                <IoCheckmarkCircleSharp size={30}/>
                                <p>Double Taxation</p>
                            </div>
                        </div>
                    </div>
                    <p>Corporate tax planning aims to minimize a business's tax liability within the legal framework. This can involve structuring the business in a tax-efficient manner, utilizing available deductions and credits, and optimizing business activities to reduce the overall tax burden. Many corporations seek the expertise of tax professionals or tax advisory services to ensure compliance and make informed decisions regarding their corporate tax responsibilities.</p>
                </div>
                <div className="personal-contact-btn">
                    <h3>Contact us for pricing today.</h3>
                    <Link to='/contact'>
                        <button>Contact Us</button>
                    </Link>
                </div>
            </div>
        </div>
    );
}
 
export default CorpTax;