import { useState } from "react";
import { IoAddCircleOutline, IoAddCircle } from "react-icons/io5"

const FAQItem = ({question, answer}) => {
    const [toggle, setToggle] = useState(false);
    const handleClick = (e) => {
        setToggle(!toggle);
    };

    return (
        <div className="FAQ-questions-item">
            <div className="FAQ-questions-item-title" onClick={handleClick}>
                <strong><p>{question}</p></strong>
                {!toggle ? <IoAddCircleOutline size={30}/> : <IoAddCircle size={30}/>}
            </div>
            <div className={`FAQ-questions-item-answer${!toggle ? "-disable" : ""}`}>
                <p>{answer}</p>
            </div>
        </div>
    );
}
 
export default FAQItem;