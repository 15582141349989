import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Navbar from './components/Navbar';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Resources from './pages/Resources';
import Contact from './pages/Contact';
import Footer from './components/Footer';
import PersonalTax from './pages/serviceRoutes/PersonalTax';
import CorpTax from './pages/serviceRoutes/CorpTax';
import GSTFiling from './pages/serviceRoutes/GSTFiling';
import Payroll from './pages/serviceRoutes/Payroll';
import BookKeeping from './pages/serviceRoutes/BookKeeping';
import BusSetup from './pages/serviceRoutes/BusSetup';
import Tools from './pages/resourceRoutes/Tools';
import News from './pages/resourceRoutes/News';
import FAQ from './pages/resourceRoutes/FAQ';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Navbar />
        <div className="pages">
          <Routes>
            <Route 
              path='/'
              element={<Home />}
            />
            <Route 
              path='/about'
              element={<About />}
            />
            <Route 
              path='/services'
              element={<Services />}
            />
            <Route 
              path='/services/personaltax'
              element={<PersonalTax />}
            />
            <Route 
              path='/services/corporatetax'
              element={<CorpTax />}
            />
            <Route 
              path='/services/gstfiling'
              element={<GSTFiling />}
            />
            <Route 
              path='/services/payroll'
              element={<Payroll />}
            />
            <Route 
              path='/services/bookkeeping'
              element={<BookKeeping />}
            /> 
            <Route 
              path='/services/business-setup'
              element={<BusSetup />}
            />                                               
            <Route 
              path='/resources'
              element={<Resources />}
            />
            <Route 
              path='/resources/news'
              element={<News />}
            />
            <Route 
              path='/resources/FAQ'
              element={<FAQ />}
            />
            <Route 
              path='/resources/tools'
              element={<Tools />}
            />
            <Route 
              path='/contact'
              element={<Contact />}
            />
          </Routes>
        </div>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
