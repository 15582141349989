import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import './Contact.css'
import Header from '../components/Header';
import { IoCall, IoMail, IoLocationSharp } from "react-icons/io5";
import { Link } from 'react-router-dom';

const Contact = () => {
    const form = useRef();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNum, setPhoneNum] = useState('');
    const [message, setMessage] = useState('');
    const [btnStatus, setBtnStatus] = useState('contact-submit-button');
    const [btnDisable, setBtnDisable] = useState(false);
    const [btnValue, setBtnValue] = useState('Submit');

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_qty5wdy', 'template_svq8cpk', form.current, 'gTJiSJGdtZxo4VVlf')  
            .then((result) => {
                console.log(result.text);
                setFirstName('');
                setLastName('');
                setEmail('');
                setPhoneNum('');
                setMessage('');
                setBtnStatus('contact-submit-button-disable');
                setBtnValue('Sent')
                setBtnDisable(true);
            }, (error) => {
                console.log(error.text);
            });
    };

    return (
        <div className="contact-container">
            <div className="contact-title-banner">
                <Header 
                    name="Contact Us"
                    desc="We're ready to offer our help"
                    image='../acct_image8.jpg'
                />
            </div>
            <div className ="contact-area-container">
                <div className ="contact-form">
                    <div className="contact-form-box">
                        <form ref={form} onSubmit={sendEmail}>
                            <h4 className="contact-form-title">Send a Message</h4>
                            <div className="contact-input-box">
                                <div>
                                    <label htmlFor="fname">First Name*</label>
                                    <input 
                                        value={firstName}
                                        onChange={(e) => {setFirstName(e.target.value)}}
                                        type="text" 
                                        id="fname" 
                                        name="fname" 
                                        placeholder="John" 
                                        required
                                    />
                                </div>
                                <div>
                                    <label htmlFor="lname">Last Name*</label>
                                    <input 
                                        value={lastName}
                                        onChange={(e) => {setLastName(e.target.value)}}
                                        type="text" 
                                        id="lname" 
                                        name="lname" 
                                        placeholder="Doe" 
                                        required
                                    />
                                </div>
                            </div>
                            <div className="contact-input-box">
                                <div>
                                    <label htmlFor="email">Email*</label>
                                    <input
                                        value={email}
                                        onChange={(e) => {setEmail(e.target.value)}}
                                        type="text" 
                                        id="email" 
                                        name="email" 
                                        placeholder="johndoe@email.com" 
                                        required
                                    />
                                </div>
                                <div>
                                    <label htmlFor="pnumber">Phone Number</label>
                                    <input 
                                        value={phoneNum}
                                        onChange={(e) => {setPhoneNum(e.target.value)}}
                                        type="text" 
                                        id="pnumber" 
                                        name="pnumber" 
                                        placeholder="(888) 888-8888"
                                    />
                                </div>
                            </div>
                            <div className ="contact-input-box contact-message">
                                <div>
                                    <label htmlFor="message">Message*</label>
                                    <textarea 
                                        value={message}
                                        onChange={(e) => {setMessage(e.target.value)}}
                                        type="text" 
                                        id="message" 
                                        name="message" 
                                        placeholder="Write your message here...." 
                                        required
                                    ></textarea>
                                </div>
                            </div>
                            <div className ="contact-submit">                       
                                <button type="submit" className={btnStatus} disabled={btnDisable}>{btnValue}</button>
                            </div>
                        </form>
                    </div>
                    <div className="contact-info-box">
                    <h4 className="contact-info-title">Contact Info</h4>
                        <div className="contact-info-details">
                            <ul className="contact-info-details-list">
                                <li><IoCall size={25}/> (403) 852-1565</li>
                                <li><IoMail size={25}/> <Link to="mailto:nmehmi@hotmail.com">nmehmi@hotmail.com</Link></li>
                                <li><IoLocationSharp size={25}/> 31 Cityspring Bay NE</li>
                            </ul>
                        </div>
                        <div className="contact-map">
                            <iframe title="myiframe" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2502.8095527010673!2d-113.9530995!3d51.148863!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x537163da5cc3e3ad%3A0x7331ecf2457b93e5!2s31%20Cityspring%20Bay%20NE%2C%20Calgary%2C%20AB%20T3N%201A8!5e0!3m2!1sen!2sca!4v1697138021677!5m2!1sen!2sca" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>                   
                        </div>
                    </div>
                </div> 
            </div>
        </div>
    );
}
 
export default Contact;