import Header from "../../components/Header";
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import { Link } from 'react-router-dom';

const Payroll = () => {
    return (
        <div>
            <Header 
                name="Payroll" 
                desc="Organize and maintain your business' payroll."
                image='../acct_image5.jpg'
            />
            <div className="personal-container">
                <h3>Making sense of your Payroll</h3>
                <p></p>
                <div className="personal-desc-container">
                    <p>Payroll is the process of managing and administering employee compensation within an organization. This critical function involves calculating and distributing employee salaries, wages, bonuses, and deductions while ensuring compliance with tax laws and other regulatory requirements. Payroll processing encompasses several key elements:</p>
                    <div className="personal-desc-checklist">
                        <div>
                            <div className="checklist-item">
                                <IoCheckmarkCircleSharp size={30}/>
                                <p>Salary Calculations</p>
                            </div>
                            <div className="checklist-item">
                                <IoCheckmarkCircleSharp size={30}/>
                                <p>Deductions</p>
                            </div>
                            <div className="checklist-item">
                                <IoCheckmarkCircleSharp size={30}/>
                                <p>Bonuses and Overtime</p>
                            </div>
                            <div className="checklist-item">
                                <IoCheckmarkCircleSharp size={30}/>
                                <p>Direct Deposits and Payroll Checks</p>
                            </div>
                        </div>
                        <div>
                            <div className="checklist-item">
                                <IoCheckmarkCircleSharp size={30}/>
                                <p>Leave and Time-off Tracking</p>
                            </div>
                            <div className="checklist-item">
                                <IoCheckmarkCircleSharp size={30}/>
                                <p>Payroll Taxes</p>
                            </div>
                            <div className="checklist-item">
                                <IoCheckmarkCircleSharp size={30}/>
                                <p>Tax Compliance</p>
                            </div>
                            <div className="checklist-item">
                                <IoCheckmarkCircleSharp size={30}/>
                                <p>Record Keeping</p>
                            </div>
                        </div>
                        <div>
                            <div className="checklist-item">
                                <IoCheckmarkCircleSharp size={30}/>
                                <p>Reports and Documentation</p>
                            </div>
                            <div className="checklist-item">
                                <IoCheckmarkCircleSharp size={30}/>
                                <p>Employee Benefits</p>
                            </div>
                            <div className="checklist-item">
                                <IoCheckmarkCircleSharp size={30}/>
                                <p>Employee Self Service</p>
                            </div>
                        </div>
                    </div>
                    <p>Efficient payroll management is crucial for ensuring employees are compensated accurately and on time while meeting tax and legal requirements. Many businesses use payroll software or outsource their payroll processes to specialized providers to streamline this essential aspect of human resource management.</p>
                </div>
                <div className="personal-contact-btn">
                    <h3>Contact us for pricing today.</h3>
                    <Link to='/contact'>
                        <button>Contact Us</button>
                    </Link>
                </div>
            </div>
        </div>
    );
}
 
export default Payroll;