import Header from "../components/Header";
import { Link } from 'react-router-dom';
import './About.css';

const About = () => {
    return (
        <div className="about-container">
            <Header 
                name="About Us" 
                desc="We provide tax and bookkeeping services for you and your small business."
                image='../acct_image7.jpg'
            />
            <div className="about-information-container">
                <h2>About Incan Tax & Accounting Inc.</h2>
                <div className="about-information">
                    <div className="about-information-title">
                        <h4>Proudly Serving the Calgary Community for 14+ Years</h4>
                        <p>Incan Tax & Accounting is a leading small business accounting, tax, and business consulting firm. We have been serving the needs of our client's needs since 2009.</p>
                        <p>Whether you are a startup, small or medium business, or a well established business needing help with your business's accounting and tax filings, we have the tools and expertise to serve your needs. Incan Tax & Accounting is known for our quality, professionalism, and knowledge of the latest tax and accounting standards. As a full-service accounting firm, our strategy is to provide seamless services throughout the clients journey. We provide multiple services including:</p>
                        <ol>
                            <div className="about-information-title-list">
                                <li>Personal Tax</li>
                                <li>Corporate Tax</li>
                                <li>GST Filing</li>
                            </div>
                            <div className="about-information-title-list">
                                <li>Payroll</li>
                                <li>Bookkeeping</li>
                                <li>Business Setup</li>
                            </div>
                        </ol>
                        <p>In addition to these, we go out of our way to address any other business needs for you and your company.</p>
                        <div className="about-information-buttons">
                            <Link to='/services'>
                                <button>VIEW OUR SERVICES</button>
                            </Link>
                            <Link to='/contact'>
                                <button>CONTACT US</button>
                            </Link>
                        </div>
                    </div>
                    <div className="about-information-image">
                        <img src="../acct_image3.jpg" alt="aboutimage" />
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default About;