import Header from "../components/Header";
import { Link } from 'react-router-dom'
import { IoNewspaperOutline, IoHelpCircleOutline, IoCalculatorOutline } from "react-icons/io5"
import './Resources.css'

const Blog = () => {
    return (
        <div>
            <Header 
                name="Resources" 
                desc="Tools to get you started with your personal or business tax needs."
                image='../acct_image6.jpg'
            />
            <div className="resources-container">
                <h3>Helping you navigate your tax journey.</h3>
                <p>There are many accounting hurdles whether you're an individual or small business. Below you can find the latest news, frequenty asked questions (FAQs), and tools to help you on your tax journey.</p>
                <div className="resources-links-container">
                    <div className="resources-links-item-container">
                        <Link to='/resources/news'>
                            <div className="resources-links-item-title">
                                <IoNewspaperOutline size={120}/>
                                <h3>Tax News</h3>
                            </div>
                        </Link>
                        <Link to='/resources/FAQ'>
                            <div className="resources-links-item-title">
                                <IoHelpCircleOutline size={120}/>
                                <h3>FAQ</h3>
                            </div>
                        </Link>
                        <Link to='/resources/tools'>
                            <div className="resources-links-item-title">
                                <IoCalculatorOutline size={120}/>
                                <h3>Helpful Tools</h3>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default Blog;